.disable{
  opacity: 0.6;
  pointer-events: none;
}
.SearchCapas {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}
.labelCapas {
  width: 100%;
}

.esri-icon-non-visible:before {
  content: "\e610" !important;
  color: inherit;
}

.esri-icon-visible:before {
  content: "\e611" !important;
  color: inherit;
}

.esri-icon-right-triangle-arrow:before {
  content: "\e620" !important;
  color: inherit;
}

.esri-layer-list__item-container .esri-icon-down-arrow:before {
  content: "\e621" !important;
  color: inherit;
}


.oculto {
  display: none;
}

.tituloPE{
  font-size: 14px;
  font-weight: bold;
}
table.TablaPE {
  border: 1px solid #000000;
  background-color: #EEEEEE;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table.TablaPE td, table.TablaPE th {
  border: 1px solid #AAAAAA;
  padding: 3px 2px;
}
table.TablaPE tbody td {
  font-size: 13px;
}
table.TablaPE tr:nth-child(even) {
  background: #F4F4F4;
}
table.TablaPE thead {
  background: #444444;
  border-bottom: 2px solid #444444;
}
table.TablaPE thead th {
  font-size: 12px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  border-left: 2px solid #D0E4F5;
}
table.TablaPE thead th:first-child {
  border-left: none;
}

table.TablaPE tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  background: #D0E4F5;
  background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  border-top: 2px solid #444444;
}
table.TablaPE tfoot td {
  font-size: 14px;
}
table.TablaPE tfoot .links {
  text-align: right;
}
table.TablaPE tfoot .links a{
  display: inline-block;
  background: #1C6EA4;
  color: #FFFFFF;
  padding: 2px 8px;
  border-radius: 5px;
}