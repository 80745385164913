html,
body,
.mapDiv {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}


.appContent{
  background-color: rgb(0, 0, 0);
    
    z-index: 100000;
    position: absolute;
    width: 100%;
    height: 100%;
}


nav{
  display: none;
}

body {
  display: flex;
}

.divNone{
  display: none;
}

.divFlex {
  display: flex;
}

calcite-loader {
  align-self: center;
  justify-self: center;
}

.div-center{
  text-align: center;
}
.divIntroCenter{
  position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}
@media screen and (max-width: 480px) {
  .movilOcular {display: none !important;}
  .divMovil{margin-top: 350px !important;}
  
}

calcite-label {
  --calcite-label-margin-bottom: 0px;
}

calcite-rating {
  margin-top: 0.25rem;
}

.label-wrapper {
  display: flex;
  margin-inline: 1rem;
  padding: 0.5rem;
  border: 1px solid var(--calcite-ui-border-1);
  cursor: pointer;
}

calcite-switch {
  margin: 0 0.5rem;
}

#divCounterVistors {
  background-color: white;
  display: inline-flex;
  padding: 8px;
  color: black;
  vertical-align: middle;
}

#overviewDiv {
  width: 280px;
  height: 200px;
  border: 2px solid black;
  z-index: 2;
  overflow: hidden;
}

#extentDiv {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 2;
}

.esri-widget--button {
  border: 0px;
}

.esri-widget--button.active,
.esri-widget--button.active:hover,
.esri-widget--button.active:focus {
  cursor: default;
  background-color: #999696;
}

.esri-widget--button.active path,
.esri-widget--button.active:hover path,
.esri-widget--button.active:focus path {
  fill: #e4e4e4;
}

.search-container {
  visibility: hidden;
}

/* ======= START CSS RBENAVENTE ======= */
/* ---------------------------------------- */
/* START HOME PAGE ===== */
/* Video ----- */
.bgVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #0c111a;
}
.bgVideo video {
  height: 50%;
  margin: 0;
}
.bgVideo .bgGradient {
  background: rgb(18, 18, 18);
  background: linear-gradient(
    0deg,
    rgba(18, 18, 18, 1) 50%,
    rgba(12, 17, 26, 0) 100%
  );
  width: 100%;
  height: 60%;
  position: absolute;
  top: 15%;
  left: 0;
}
/* Brand Message ----- */
.msgHome {
  width: 92%;
  padding: 22px 20px;
  margin: 0;
  height: 100%;
  position: relative;
  border-radius: 12px;
  color: #fff;
  background: transparent;
}
.lightHome .msgHome{
    background-color: #fff;
    color: inherit;
}
.msgHome .logoHome img {
  width: 200px;
}
.msgHome .ulNews li:first-child {
  margin-left: 0;
  margin-bottom: 4px;
  list-style: none;
}
.msgHome .ulNews li {
  margin-left: 18px;
  line-height: 26px;
  font-size: 15px;
}
.msgHome .terms {
  font-size: 15px;
  line-height: 20px;
}
.msgHome .terms a {
  font-weight: 500;
  text-decoration: none;
}
.msgHome .terms a:hover {
  text-decoration: underline;
}
/* END HOME PAGE === */

.btnHome-rb {
  text-transform: capitalize;
  font-size: 1.2rem;
  vertical-align: revert;
  line-height: 32px;
  /* border-radius: 80px; */
  padding-left: 22px;
  background-color: #007ac2;
  border: none;
}
.btnHome-rb:hover{
  background-color: #00619b;
}
.btnHome-rb calcite-icon{
  vertical-align: bottom;
  margin: 0 0 0 6px;
}


/* ---------------------------------------- */
/* START MAP PAGE ===== */
/* - */
/* Start header --- */
/* - */
.head-rb{
  height: 108px;  
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.08)!important;
  background-color: transparent !important;
}
.head-rb calcite-panel{
  background-color: #00000072 !important;
}
.logo-rb{
    width: 162px;
    height: 32px;
    left: 16px;
    top: 16px;
}
.ulTools-rb {
  top: 16px;
  right: 16px;
}

.ulTools-rb .liTool-rb {
  float: left;
  clear: right;
  min-width: 32px;
  min-height: 32px;
  text-align: center;
  border: none;
}
.ulTools-rb .liConsult{
  width: 100%;
}
.ulTools-rb .liConsult calcite-select {
  float: left;
  clear: right;
}
.ulTools-rb .liConsult .typeConsult {
  width: 40%;
}
.ulTools-rb .liConsult .itemsConsult {
  width: 60%;
}
.ulTools-rb .liSearch{
  display: none;
  /*width: 100%;*/
}
.ulMovil-rb{
  top: 58px;
  left: 16px;
  right: 16px;
}

.header-rb {
  position: absolute;
  left: 0;
  height: 64px;
  width: 100%;
  padding: 12px 16px;
  top: 0;
}
/* End header --- */
/* - */
.rb-container{
}

.mx-table{
  margin: 0 11px;
  overflow-x: auto;
}
/* - */
/* Start panel */
.flap-rb{
  top: 16px;
  left: 16px;
}
.hiddenPanel .flap-rb{
  left: 16px;
}
.hiddenPanel .rb-container .rb-panel{
  margin-top: 126px;
  box-shadow: none !important;
  padding: 0 16px;
}

.hiddenPanel .rb-container .rb-map{
  width: 100%;
}

.rb-panel{
  /* width: 100%; */
  max-width: 400px;
  z-index: 1000;
}

.widget-panel {
  font-size: 18px;
  font-weight: bold;
}

.rb-detail{
  min-height: calc(100% - 232px);
  padding-top: 164px;
}
#test .notice-close{
  height: 48px !important;
}
/* End panel */
/* - */
/* Start map */
.rb-map{
  width: 100%;
  position: absolute;
  right: 0;
}
.rb-map .divMap{
}
.rb-map .rb-results{
  height: 350px;
  overflow-y: auto;
}
/* End map */
/* - */
/* Start footer --- */
/*.footerPanel-rb{;
}*/
.rb-footer{
  position: relative;
}
.rb-footer ul{
}
.rb-footer ul li{
  border-bottom: 1px solid #ddd;
  padding: 0;
  list-style: none;
  display: inline-block;
  width: 100%;
}
.rb-footer ul li a{
  padding: 9px 0;
  width: 100%;
  display: inline-block;
  opacity: .7;
}
.copyIngemmet {
  opacity: 0.4;
}
.copyIngemmet img {
  width: 120px;
}
/* End footer --- */
/* - */
/* END MAP PAGE === */
/* ---------------------------------------- */
/* ======= END CSS RBENAVENTE ======= */
@media only screen and (min-width: 480px) {
}

@media only screen and (min-width: 1020px){
  .search-container{
    visibility: visible;
    position: absolute;
    top: 16px;
    right: 290px;
    z-index: 100;
    border: 1px solid #eeeeee;
  }

  .ulTools-rb .liSearch{
    display: inline-block;
    width: 240px;
  }
}

@media only screen and (min-width: 820px) {
  .head-rb{
    height: 64px;
  }
  .ulTools-rb .liConsult{
    width: 400px;
  }

  /* ======= START CSS RBENAVENTE ======= */
  /* ---------------------------------------- */
  /* START HOME PAGE ===== */
  /* Video ----- */
  .bgVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #0c111a;
  }

  .bgVideo video {
    height: 100%;
    margin: 0 0 0 32.5%;
  }

  .bgVideo .bgGradient {
    background: rgb(18, 18, 18);
    background: linear-gradient(
      90deg,
      rgba(18, 18, 18, 1) 50%,
      rgba(12, 17, 26, 0) 100%
    );
    width: 75%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  /* Brand Message ----- */
  .msgHome {
    position: absolute;
    width: 50%;
    height: 100%;
    padding: 40px 5% 0 5%;
    margin: 0;
    border-radius: 0;
  }
  .msgHome .logoHome img {
    width: 240px;
  }
  /* END HOME PAGE ===== */
  /* ---------------------------------------- */
  /* START MAP PAGE === */

.aco-rb .accordion{
  border: none !important;
}
calcite-notice .container {
  border: none !important;
  box-shadow: none !important;
}
.notice-content {
  padding-block: 0 !important;
  padding-inline: 0 !important;
}
calcite-notice .message {
  margin: 0 !important;
}


.logo-rb{
  left: 16px;
  width: 200px;
  height: 40px;
  top: 12px;
}

/* Start panel */
.flap-rb{
  top: 80px;
  left: 32%;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.08)!important;
}
.hiddenPanel .flap-rb{
  left: 16px;
}

.rb-panel{
  width: 32%;
  /*height: calc(100% - 64px);*/
  margin-top: 82px !important;
}

.rb-detail{
  min-height: calc(100% - 232px);
  padding-top: 16px;
}
/* End panel */
/* - */

.rb-map{
  width: 68%;
}
.rb-map .rb-results{
  /*height: 80%;*/
  height: 400px;
  overflow-y: auto;
}

/* Start footer --- */
.rb-footer{
}
.rb-footer ul li {
  float: left;
  clear: right;
  list-style: none;
  line-height: 18px;
  padding: 0 8px;
  border-left: 1px solid #ddd;
  width: auto;
  border-bottom: none;
}
.rb-footer ul li:first-child {
  padding: 0 8px 0 0;
  border-left: none;
}
.rb-footer ul li a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  padding: 0;
}
.rb-footer ul li a:hover {
  text-decoration: underline;
  opacity: 1;
}
.copyIngemmet {
  opacity: 0.4;
}

.copyIngemmet img {
  width: 120px;
}
/* End footer --- */

  /* END MAP PAGE === */
  /* ---------------------------------------- */
  /* ======= END CSS RBENAVENTE ======= */
}
.msgCatastro {
  font-size: smaller;
  color: #6a6a6a;
}

.widget{
  border: 1px solid #808080;
  box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.75)
}

.custom-widget-table-header {
  padding: 6px;
}

.custom-widget-table-cell{
  padding: 5px;
  font-size: small;
}

.custom-widget-table-row tr{
  border-bottom: 0.1em dotted #808080;
}

.custom-widget-table-row tr:hover{
  filter:invert(60%);
  cursor: pointer;
}