nav {
  text-align: center;
  margin-top: 10px;
  display: none;
}

nav ul {
  list-style: none;
  display: inline-block;
  padding: 5px;
}

nav ul li {
  float: left;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

nav ul li a {
  color: black;
  font-weight: bold;
  text-decoration: none;
  font-size: 15px;
  padding: 4px;
}

nav ul li a:hover {
  background-color: #929fb3;
}
